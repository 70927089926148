@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


footer {
  text-align:center;
  
}

footer a {
  color: #09d3ac;
}
.App {
  background-color: #282c34;
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #09d3ac;
}

body {
  background-color: #282c34;
  font-family: 'Roboto';
}
.heading {
  font-weight: 700;
  letter-spacing: 0.05em;
}
.subheading {
  font-weight: 300;
}
