@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.App {
  background-color: #282c34;
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #09d3ac;
}

body {
  background-color: #282c34;
  font-family: 'Roboto';
}
.heading {
  font-weight: 700;
  letter-spacing: 0.05em;
}
.subheading {
  font-weight: 300;
}